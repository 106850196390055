import React, {FormEvent, useCallback, useEffect, useMemo, useRef, useState} from "react";
import styles from "./Users.module.scss";
import {makeStyles} from '@material-ui/core/styles';
import {inject, observer} from "mobx-react";
import {UserData} from "../../../models/general";
import dataStore from "../../../stores/dataStore";
import {RouteComponentProps} from "react-router-dom";
import messageStore from "../../../stores/messageStore";
import {useTranslation} from "react-i18next";
import profileBlank from "../../../assets/profile-blue.svg";
import AddUserTaxand from "./addUser/AddUserTaxand";
import {API} from "../../../api";
import {
    areaOfIndustryExpertiseRef,
    countryRef,
    gradeRef,
    taxSpecialismRef,
    usersRef,
} from "../../../services/firebase/firebase";
// ... Firebase imports
import firebase from 'firebase/app';
// ... Firebase imports
import {Checkbox, TablePagination, TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import {ExportOutlined, PlusOutlined} from '@ant-design/icons';
import * as FileSaver from "file-saver";
import {Button, Modal, notification, Skeleton} from "antd";
import {taxSpecialismeListWordpress} from "../../wordpress-conf/tax-specialism-conf";
import UserInfo from './UserInfo/UserInfo';
import {Search} from "@material-ui/icons";
import MaterialTable from "material-table";
import {Header} from "../../../models/tables";
import * as XLSX from 'sheetjs-style';
import {ApprovalStatus} from "../../../models/ApprovalStatusEnum";
import ExportUser from "./importUsersFromExcel/ExportUser";
//icons
const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;
let removeDiacritics = require('diacritics').remove

// Style
const useStyles = makeStyles((theme) => ({
    formControlFirmInput: {
        minWidth: 225,
        maxWidth: 220,
        marginLeft: "20px",
    },
    textfield:{
        color:"#681d49",
        borderColor:"#681d49"
    }
}));

interface UsersProps extends RouteComponentProps {
}
interface UserDetail {
    email: string;
    firstName: string;
    lastName: string;
}

const Users = (props: UsersProps) => {
    const [token, setToken] = useState('');
    const [openImport, setOpenImport] = React.useState(false);
    //style class
    const classes = useStyles();
    //translate
    const {t} = useTranslation();

    //confirm modal
    //const { confirm } = Modal;

    //type action = Add,Update,Delete
    const [action , setAction] = useState<any>("");
    const [pictureDeleted,setPictureDeleted]=useState(false)
    //image state
    const [file1,setfile1]=useState<any>();

    //Loading process
    const [rightLoading , setrightLoading] = useState(true);
    const [working, setWorking] = useState(false);


    // Data Load Field State
    const [listGrade, setListGrade] = React.useState([]);
    const [listTaxSpecialism, setListTaxSpecialism] = React.useState<string[]>([]);
    const [listAreaOfIndustryExpertise, setListAreaOfIndustryExpertise] = React.useState<string[]>([]);
    const [currentUserRight, setCurrentUserRight] = useState("");
    const [currentUser, setCurrentUser] = useState<any>("");
    const [assistants,setAssistants]=useState<any>([])

    // Open UserInfo Component State
    const [openUserInfo, setOpenUserInfo] = React.useState(false);
    const [openNewUser, setOpenNewUser] = React.useState(false);
    const [openUpdateUser, setOpenUpdateUser] = React.useState(false);

    // Custom Filter
    const [filterCountry, setFilterCountry] = React.useState<string[]>([]);
    const [filterGrade, setFilterGrade] = React.useState("");
    const [filterTaxSpecialism, setFilterTaxSpecialism] = React.useState<string[]>([]);
    const [filterAreaOfIndustryExpertise, setFilterAreaOfIndustryExpertise] = React.useState<string[]>([]);


    //Data User State That Will Be Send To UpdateUserTaxand Component
    const [dataRow, setDataRow] = React.useState<any>({
        email: "",
        id: "",
        firstName: "",
        lastName: "",
        phone: "",
        firm: "",
        biography: "",
        right: "",
        mobile: "",
        isVisible: false,
        deleted: false,
        grade: "",
        country: "",
        personnalBiography: "",
        profesionnalBiography: "",
        linkedInProfileLink: "",
        twitterProfileLink: "",
        assistantName: "",
        assistantEmail: "",
        assistantTelephone: "",
        assistantMobile: "",
        taxSpecialism: "",
        areaOfIndustryExpertise: "",
        showLandingModal: false,
        approvalStatus: ApprovalStatus.Default
    });
    const [isVisible, setIsVisible] = useState(action === "Edit" ? dataRow.isVisible : false);
    const [assistant,setAssistant]=useState<any>("");
    const [taxSpecialism, setTaxSpecialism] = React.useState<any>(action === "Edit" ? (!!dataRow.taxSpecialisme ? dataRow.taxSpecialisme : []) : []);
    const [grade, setGrade] = React.useState<any>(action === "Edit" ? (!!dataRow.grade ? dataRow.grade : "") : "");
    const [DeletedusernameVerified, setDeletedUsernameVerified] = useState(true);
    const [countryId, setCountryId] = useState("");
    const [firm, setFirm] = React.useState<any>(action === "Edit" ? (!!dataRow.firm?.firm ? dataRow.firm?.firm : "") : "");
    const [areaOfIndustryExpertise, setAreaOfIndustryExpertise] = React.useState<any>(action === "Edit" ? (!!dataRow.areaOfIndustryExpertise ? dataRow.areaOfIndustryExpertise : []) : []);
    const [country, setCountry] = React.useState<any>(action === "Edit" ?(!!dataRow.country ? dataRow.country : "") : "");
    const [right, setRight] = useState(action === 'Edit' ? dataRow.right : "");
    const [data, setData] = useState({
        email: "",
        password: "",
        againPassword: "",
        firstName: "",
        lastName: "",
        generatedPass: "",
        phone: "",
        mobile: "",
        personnalBiography: "",
        profesionnalBiography: "",
        deleted: false,
        linkedInProfileLink: "",
        twitterProfileLink: "",
        assistantName: "",
        assistantEmail: "",
        assistantTelephone: "",
        assistantMobile: "",
        showLandingModal:false,
        approvalStatus: ApprovalStatus.Default
    });

    //verification state
    const [emailVerified, setEmailVerified] = useState(true);
    const [emailValid, setEmailValid] = useState(true);
    const [usernameVerified, setUsernameVerified] = useState(true);
    const [fileChanged, setFileChanged] = useState(false);
    const [filterTableActive, setFilterTableActive] = useState(false);
    const [filterTable, setFilterTable] = useState<any>([]);

    let wordpress_id:any;
    let imageWordpressId:any;
    let deleteduserwordpressId:any;
    let characters="abcdefghijklmnopqrstuvwxyz";

    // Get Country Id from List Country Firebase
    const getCountryId =(countryName:string)=>{
        countryRef.on('value', async (snap) => {
            let result = snap.val();
            for (let country in result) {
                const detailedCountry = await API.getCountryInfo(country);
                if(detailedCountry===countryName){
                    setCountryId(country);
                    break;
                }
            }

        });

    }

    // Convert Blob To File
    const blobToFile = (theBlob: Blob, fileName:string): File => {
        return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type })
    }
    //Create Random Name File Image
    function makeid(length:any) {
        let result           = '';
        let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for ( let i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }

    //replace Special Character In Custom Filter
    function replaceSpecialChars(str:string)
    {
        let res=""
        if(str) {
            res = removeDiacritics(str)
        }

        return res?res:str
    }

    const updateUserAssitantInfo=async (assistantId:string)=>{
        const updatedInfo:any={
            assistantName: dataRow.firstName+" "+dataRow.lastName,
            assistantTelephone: dataRow.phone,
            assistantMobile: dataRow.mobile,
        }
        usersRef.on('value', async (snap) => {
            let users = snap.val();
            for (let user in users) {
                const detailedUser = await API.getUserInfo(user);
                if (detailedUser.assistantId) {
                    if (detailedUser.assistantId === assistantId) {
                        await API.updateUser(updatedInfo, detailedUser.id)
                    }
                }

            }});
    }


    // Filter TaxSpecialismList To Be include In The List /Wordpress-conf/taxSpecialism.tsx
    const filterTaxSpecialisme=(taxSpecialismList:string[])=>{
        let intersection = taxSpecialismList.filter(x => taxSpecialismeListWordpress.includes(x));
        return intersection
    }

    // Upload Avatar firebase
    const uploadImage = useCallback(async (id) => {
        await firebase.storage().ref("userAvatar/" + id + ".jpg").put(file1);
        await firebase.storage().ref("userAvatar").child(id + ".jpg").getDownloadURL().then(url => {
            const updates: any = {};
            updates['avatarUrl'] = url;
            firebase.database().ref('users/' + id).update(updates)
        })
    },[file1])// eslint-disable-line react-hooks/exhaustive-deps

    // Delete avatar from Firebase
    const deleteImageFirebase = useCallback(async (id) => {
        const updates: any = {};
        updates['avatarUrl'] = "";
        firebase.database().ref('users/' + id).update(updates);
    },[file1])// eslint-disable-line react-hooks/exhaustive-deps


    // Download Image From Firebase And Upload It To Wordpress
    const uploadImageWordpress=useCallback(async ()=>{
        let id:any;
        let formData=new FormData();
        const userId = dataRow.id;// eslint-disable-line react-hooks/exhaustive-deps
        let oldRef = await firebase.storage().ref("userAvatar").child(userId + ".jpg")
        await oldRef.getDownloadURL().then(async url => {
            let blob = await fetch(url).then(r => r.blob())
            let myFile = blobToFile(blob, makeid(6)+".png");
            formData.append("file",myFile)
            await fetch(`${process.env.REACT_APP_WORDPRESS_API}/wp-json/wp/v2/media`,{
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("wordpressToken")} `,
                },
                body: formData
            }).then(async response => await response.json())
                .then( res => {
                    id=res.id
                    dataStore.updateImageWordPressSelected(id)
                });
        })
    },[dataRow])

    const canSubmit = useMemo(
        () =>
            !!data.email && !!data.password && data.password === data.againPassword && data.firstName && data.lastName && right,
        [data,right]
    );

    // Change Biography Formt To Be Accept By WordPress
    const textFormat=(biography:any)=>{
        let text:string=biography;
        let p:string="<p>";
        let pEnd:string="</p>";
        let count=0;
        for(let i=0;i<data.profesionnalBiography.length;i++){
            if(count%2===0) {
                text = text.replace("\n", pEnd + '&nbsp;');
                count++
            }else {
                text = text.replace("\n", p);
                count++
            }

        }
        text=text+pEnd;
        return text
    }

    // Upload Image WordPress And Get id
    const getMediaId=useCallback( async ()=>{

        let id:any;
        const formData=new FormData();
        formData.append("file",file1)
        await fetch(`${process.env.REACT_APP_WORDPRESS_API}/wp-json/wp/v2/media`,{
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("wordpressToken")} `,
            },
            body: formData
        }).then(async response => await response.json())
            .then(data => {

                id=data.id

            });
        return id
    },[file1])

    // Check If New Email Added Already Exist And Get His Wordpress Id
    // const checkUserWordpress=async (email:string) => {
    //     const users=dataStore.deletedUsers;
    //     let id;
    //     for (let user in users) {
    //         if(users[user].email===email && users[user].wordpress_id){
    //             id=users[user].wordpress_id;
    //             break
    //         }
    //     }
    //     return id;
    // }
    const checkUserWordpress = async (email: string) => {
        try {
            const tokenWordpress = localStorage.getItem("wordpressToken");
            const response = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/getUserWordPressById?email=${email.trim()}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${tokenWordpress}`,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const res = await response.json();
                return res.userId;
            } else if (response.status === 404) {
                return null; // Return null if user not found
            } else {
                throw new Error(`Error: ${response.status}`);
            }
        } catch (error) {
            console.error('Error checking WordPress user:', error);
            throw error;
        }
    };
    const shortPassword = useMemo(
        () => data.password.length > 0 && data.password.length < 8,
        [data]
    );

    // Load Data User
    const loadData = useCallback(async () => {
        try {
            await API.getAllUserInfo().then(res => {
                const cleanedData = res.filter(( user: UserData) => user.firstName && user.lastName);
                dataStore.updateUsers(cleanedData);
                setRows(cleanedData);
                API.getDeletedUserInfo().then(delRes=>{
                    dataStore.updateDeletedUsers(delRes);
                });
            });
        } catch (error) {
            messageStore.snackbar({
                message: t("Failed fetching users"),
                type: "error",
            });
        }
    }, [t]);

    // ACF User Data Wordpress
    const userWordpressUpdateACF= {
        fields: {
            display_email: data.email,
            telephone: data.phone,
            mobile: data.mobile,
            biography: !!data.profesionnalBiography ?textFormat(data.profesionnalBiography):"",
            connect_url: data.linkedInProfileLink,
            assistants_name: data.assistantName,
            assistants_email: data.assistantEmail,
            position: grade,
            user_location: countryId,
            show: isVisible,
            area_of_expertise: taxSpecialism?.length > 0 ? filterTaxSpecialisme(taxSpecialism) : "",
            company_logo:firm?.logo_id

        }
    }

    let randomCharacter=characters.charAt(Math.floor(Math.random() * characters.length))

    // Native User Data Wordpress
    const userWordpressUpdateNative={
        name:data.firstName.replace(/[^a-zA-Z0-9]/g,randomCharacter)+" "+data.lastName.replace(/[^a-zA-Z0-9]/g,randomCharacter),
        first_name: data.firstName,
        last_name: data.lastName,
        url: !!firm ? firm.webSite :"",
    }


    //  Combine All Four Filter inside table
    const filter=(data:any)=>{

        let result:any[]=data

        if(filterCountry.length>0){
            result= result.filter(user => filterCountry.includes(user.country));
        }
        if(filterGrade){
            result= result.filter(user=>(user.grade===filterGrade))

        }
        if(filterTaxSpecialism.length>0){
            result= result.filter(user => filterTaxSpecialism.every(v => user.taxSpecialisme?.includes(v)));

        }
        if(filterAreaOfIndustryExpertise.length>0){
            result= result.filter(user => filterAreaOfIndustryExpertise.every(v => user.areaOfIndustryExpertise?.includes(v)));
        }

        return result

    };

    // Combine All Four Filter inside and outside table
    const GlobalFilter=()=>{
        filterFirstNameRef.current="";
        filterLastNameRef.current="";
        filterCountryRef.current="";
        filterGradeRef.current="";
        filterFirmRef.current="";
        let filterUsers= dataStore.users;
        for (let f in filterTable) {
            if (filterTable[f].column.field === "firstName") {
                filterFirstNameRef.current = filterTable[f].value
                filterUsers=filterUsers.filter((row:any)=>replaceSpecialChars(row.firstName?.toString()?.toLocaleLowerCase())
                    ?.startsWith(replaceSpecialChars(filterTable[f].value?.toString()?.toLocaleLowerCase())))
            }
            if (filterTable[f].column.field === "lastName") {
                filterLastNameRef.current = filterTable[f].value
                filterUsers=filterUsers.filter((row:any)=>replaceSpecialChars(row.lastName?.toString()?.toLocaleLowerCase())
                    ?.startsWith(replaceSpecialChars(filterTable[f].value?.toString()?.toLocaleLowerCase())))
            }
            if (filterTable[f].column.field === "firm") {
                filterFirmRef.current = filterTable[f].value
                filterUsers=filterUsers.filter((row:any)=>row.firm?.firm?.toString()?.toLocaleLowerCase()
                    .startsWith(filterTable[f].value?.toString()?.toLocaleLowerCase()))

            }
            if (filterTable[f].column.field === "country") {
                filterCountryRef.current = filterTable[f].value
                filterUsers=filterUsers.filter((row:any)=>row.country?.toString()?.toLocaleLowerCase()
                    .startsWith(filterTable[f].value?.toString()?.toLocaleLowerCase()))
            }
            if (filterTable[f].column.field === "grade") {
                filterGradeRef.current = filterTable[f].value;
                if (filterTable[f].value) {
                    filterUsers = filterUsers.filter((row: any) =>
                        row.grade?.toString()?.toLocaleLowerCase()?.startsWith(
                            filterTable[f].value?.toString()?.toLocaleLowerCase()
                        )
                    );
                }
            }
        }
        let users=filter(filterUsers)
        setRows(users)
        setFilterTableActive(false)
    }

    useEffect(()=>{
        GlobalFilter();
    },[filterCountry,filterGrade,filterTaxSpecialism,filterAreaOfIndustryExpertise,filterTableActive,filterTable])// eslint-disable-line react-hooks/exhaustive-deps


    const handleSubmit = useCallback(
        async (e: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement, MouseEvent>) => {
            e.preventDefault();
            if (!canSubmit) {
                return;
            }
            if (!emailValid || !emailVerified) {
                return;
            }
            // Sample function to make the fetch call with types defined for parameters.
            const callFirebaseFunction = async (endpoint: string,method:string, data: Record<string, any>) => {
                const response = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/${endpoint}`, {
                    method: method,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify(data)
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                // If 204 No Content, handle accordingly
                if (response.status === 204) {
                    return { userId: null, message: 'User created but no data returned' };
                }
                return await response.json();
            }

            setWorking(true);
            try {
                // Check If File Changed We Execute getMediaId() to Upload Image To Wordpress And Get Id
                if (fileChanged && isVisible) {
                    imageWordpressId = await getMediaId(); // eslint-disable-line react-hooks/exhaustive-deps
                }

                // Check If User Email Already Exist In Wordpress
                const id = await checkUserWordpress(data.email);

                // if User isVisible Field === True
                if (isVisible === true) {
                    //Check If Id User Already Exist
                    if (id || !DeletedusernameVerified) {
                        deleteduserwordpressId = dataStore.deletedUserWordpressId; // eslint-disable-line react-hooks/exhaustive-deps
                        // Update Native Data Wordpress For The User Exist Already
                        await callFirebaseFunction('updateUserWordpressNative','PUT', {
                            userIdWordpress: !!deleteduserwordpressId
                                ? deleteduserwordpressId
                                : id,
                            token: localStorage.getItem("wordpressToken"),
                            first_name: userWordpressUpdateNative.first_name,
                            last_name: userWordpressUpdateNative.last_name,
                            name:
                                userWordpressUpdateNative.first_name +
                                " " +
                                userWordpressUpdateNative.last_name,
                            url: !!firm.webSite ? firm.webSite : "",
                            callType: ""
                        });
                        // Update Acf Data Wordpress For The User Exist Already
                        await callFirebaseFunction('updateUserWordpressAcf', 'PUT',{
                            userIdWordpress: !!deleteduserwordpressId
                                ? deleteduserwordpressId
                                : id,
                            token: localStorage.getItem("wordpressToken"),
                            image: !!imageWordpressId ? imageWordpressId : "",
                            telephone: userWordpressUpdateACF.fields.telephone,
                            mobile: userWordpressUpdateACF.fields.mobile,
                            biography: userWordpressUpdateACF.fields.biography,
                            connect_url: userWordpressUpdateACF.fields.connect_url,
                            assistants_name: userWordpressUpdateACF.fields.assistants_name,
                            assistants_email: userWordpressUpdateACF.fields.assistants_email,
                            position: userWordpressUpdateACF.fields.position,
                            user_location: userWordpressUpdateACF.fields.user_location,
                            show: userWordpressUpdateACF.fields.show,
                            area_of_expertise:
                            userWordpressUpdateACF.fields.area_of_expertise,
                            company_logo: userWordpressUpdateACF.fields.company_logo,
                            callType: ""

                        });
                    }

                    //Check If Id User Not Exist
                    else {
                        // Create Wordpress User By Insert Native Data
                        const res = await callFirebaseFunction('createUserWordPressNativeFunction', 'POST', {
                            token: localStorage.getItem("wordpressToken"),
                            name: userWordpressUpdateNative.name,
                            username: userWordpressUpdateNative.name,
                            first_name: userWordpressUpdateNative.first_name,
                            last_name: userWordpressUpdateNative.last_name,
                            email: data.email,
                            password: data.password,
                            url: userWordpressUpdateNative.url,
                            description: "",
                            callType: ""
                        });
                        if (res.data && res.data.userId) {
                            // eslint-disable-next-line
                            wordpress_id = res.data.userId;
                        } else {
                            notification["error"]({
                                message: "User created but no ID returned",
                                description: "User created but no ID returned",
                            });
                        }
                        // To Insert ACF data Should Use Update Because Creation Support Just Native Data
                        await callFirebaseFunction('updateUserWordpressAcf', 'PUT',{
                            userIdWordpress: wordpress_id,
                            //userIdWordpress: wordpress_id,
                            token: localStorage.getItem("wordpressToken"),
                            image: imageWordpressId,
                            telephone: userWordpressUpdateACF.fields.telephone,
                            mobile: userWordpressUpdateACF.fields.mobile,
                            biography: userWordpressUpdateACF.fields.biography,
                            connect_url: userWordpressUpdateACF.fields.connect_url,
                            assistants_name: userWordpressUpdateACF.fields.assistants_name,
                            assistants_email: userWordpressUpdateACF.fields.assistants_email,
                            position: userWordpressUpdateACF.fields.position,
                            user_location: userWordpressUpdateACF.fields.user_location,
                            show: userWordpressUpdateACF.fields.show,
                            area_of_expertise:
                            userWordpressUpdateACF.fields.area_of_expertise,
                            company_logo: userWordpressUpdateACF.fields.company_logo,
                            callType: ""
                        });
                    }
                }

                if (!DeletedusernameVerified) {
                    deleteduserwordpressId = dataStore.deletedUserWordpressId; // eslint-disable-line react-hooks/exhaustive-deps
                }
                // If User Not Exist We Create It On Firebase
                const firebaseResponse =  await callFirebaseFunction('createUser', 'POST',{
                    email: data.email,
                    password: data.password,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    phone: data.phone,
                    mobile: data.mobile,
                    linkedInProfileLink: data.linkedInProfileLink,
                    twitterProfileLink: data.twitterProfileLink,
                    assistantId: !!assistant.id ? assistant.id : "",
                    assistantName: data.assistantName,
                    assistantEmail: data.assistantEmail,
                    assistantTelephone: data.assistantTelephone,
                    assistantMobile: data.assistantMobile,
                    firm: firm,
                    grade: grade,
                    country: country,
                    areaOfIndustryExpertise:
                        areaOfIndustryExpertise.length > 0 ? areaOfIndustryExpertise : "",
                    taxSpecialisme: taxSpecialism.length > 0 ? taxSpecialism : "",
                    personnalBiography: data.personnalBiography,
                    profesionnalBiography: data.profesionnalBiography,
                    right: right,
                    isVisible: isVisible,
                    deleted: data.deleted,
                    wordpress_id: !!deleteduserwordpressId
                        ? deleteduserwordpressId
                        : !!id
                            ? id
                            : !!wordpress_id
                                ? wordpress_id
                                : "",
                    showLandingModal:true,
                    approvalStatus: ApprovalStatus.Default,
                    callType: ""
                });
                //Change Image Firebase And Name It By The Id of User
                if (firebaseResponse && firebaseResponse.data && firebaseResponse.data.user_res) {
                    // If fileChanged, handle the upload
                    if (fileChanged) {
                        await uploadImage(firebaseResponse.data.user_res);
                    }
                }
                else {
                    throw new Error("User response data is invalid");
                }

                /* send email credentials after create user */
                try {
                    const responseSendEmail = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/sendEmailDetailUser`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                        body: JSON.stringify({
                            user: data.email,
                            pass: data.password,
                            receiver: data.firstName
                        })
                    });
                    // Parse and log JSON response only if the response is OK
                    if (responseSendEmail.ok) {
                        // Show success notification only once here
                        notification.success({
                            message: "Success",
                            description: "Email was sent successfully",
                        });
                    } else {
                        // If the response is not OK, handle it as an error
                        const errorResponse = await responseSendEmail.text(); // Use .text() to get the response text regardless of content type
                        throw new Error(`Server response error: ${errorResponse}`);
                    }
                } catch (error: any) {
                    console.error('Error sending email credentials:', error);
                    notification.error({
                        message: "Error",
                        description: `Failed to send emails. ${error.message}`,
                    });
                }

                // On Success Stop Submit And Close Model and Return Message Success
                setWorking(false);
                // close modal
                loadData()
                setOpenNewUser(false);
                notification["success"]({
                    message: "Success",
                    description: `User added successfully`,
                    style: {fontFamily: 'ArialBD'},
                });
                // Return All Data Field On Empty Field
                setData({
                    email: "",
                    password: "",
                    againPassword: "",
                    firstName: "",
                    lastName: "",
                    generatedPass: "",
                    phone: "",
                    mobile: "",
                    personnalBiography: "",
                    profesionnalBiography: "",
                    deleted: false,
                    linkedInProfileLink: "",
                    twitterProfileLink: "",
                    assistantName: "",
                    assistantEmail: "",
                    assistantTelephone: "",
                    assistantMobile: "",
                    showLandingModal:false,
                    approvalStatus: ApprovalStatus.Default
                });
                setTaxSpecialism([]);
                setGrade("");
                setFirm("");
                setCountry("");
                setAreaOfIndustryExpertise([]);
                setRight("");

                setIsVisible(false)
                setEmailVerified(true)
                setEmailValid(true)
                setAssistant('');

            }
            catch (error:any) {
                notification["error"]({
                    message: "An error occurred",
                    description: error.message || "An unexpected error occurred", // Provide the actual error message
                });
            }
            finally {
                setWorking(false);
                setOpenNewUser(false);
            }

        },
        [
            canSubmit,
            data,
            grade,
            country,
            wordpress_id,
            deleteduserwordpressId,
            imageWordpressId,
            areaOfIndustryExpertise,
            countryId,
            uploadImage,
            assistant,
            fileChanged,
            isVisible,
            right,
            firm,
            taxSpecialism,
            t,
            DeletedusernameVerified,
            loadData
        ]
    );

    //Filter Ref Material Table
    let filterFirstNameRef = useRef('')
    let filterLastNameRef = useRef('')
    let filterFirmRef = useRef('')
    let filterCountryRef = useRef('')
    let filterGradeRef = useRef('')


    //Load Data From Store
    let [rows,setRows] = useState<any>([]);
    const listCountries = dataStore.countries;

    //Token WordPress Validation

    // Column Of User In Material Table
    const headCells: Header[] = [
        {
            field: "avatarUrl",
            title: t(""),
            sortable: false,
            editable: "never",
            filtering:false,
            render: (rowData: UserData) => (
                <div
                    className={`${(styles.avatarSmall)} ${styles.avatar}`}
                    style={{
                        backgroundImage: `url(${!!rowData.avatarUrl ? rowData.avatarUrl : profileBlank})`,
                        backgroundPosition:'center',
                        backgroundPositionY:"0px"
                    }}
                />
            ),
        },
        {
            field: "firstName",
            title: t("First Name"),
            sortable: true,
            defaultFilter: filterFirstNameRef.current,
            customFilterAndSearch: (term:any, rowData:UserData) => !!rowData.firstName ? replaceSpecialChars(rowData.firstName.toLocaleLowerCase().trim()).startsWith(replaceSpecialChars(term.toLocaleLowerCase().trim())):""
        },
        {
            field: "lastName",
            title: t("Last Name"),
            sortable: true,
            defaultFilter: filterLastNameRef.current,
            customFilterAndSearch: (term:any, rowData:UserData) => !!rowData.lastName ? replaceSpecialChars(rowData.lastName.toLocaleLowerCase().trim()).startsWith(replaceSpecialChars(term.toLocaleLowerCase().trim())):""
        },
        {
            field: "firm",
            title: t("Firm"),
            sortable: true,
            defaultFilter: filterFirmRef.current,
            render: (rowData: UserData) => (!!rowData.firm ? rowData.firm.firm : ""),
            customFilterAndSearch: (term:any, rowData:UserData) => !!rowData.firm?.firm ? replaceSpecialChars(rowData.firm.firm.toLocaleLowerCase().trim()).startsWith(replaceSpecialChars(term.toLocaleLowerCase().trim())):""
        },
        {
            field: "country",
            title: t("Country"),
            sortable: true,
            defaultFilter: filterCountryRef.current,
            customFilterAndSearch: (term: any, rowData: UserData) =>
                !!rowData.country
                    ? replaceSpecialChars(
                        rowData.country?.toString().toLocaleLowerCase().trim()
                    ).startsWith(replaceSpecialChars(term.toLocaleLowerCase().trim()))
                    : false,
        },
        {
            field: "grade",
            title: t("Grade"),
            sortable: true,
            defaultFilter: filterGradeRef.current,
            customFilterAndSearch: (term: any, rowData: UserData) =>
                !!rowData.grade
                    ? replaceSpecialChars(
                        rowData.grade?.toString().toLocaleLowerCase().trim()
                    ).startsWith(replaceSpecialChars(term.toLocaleLowerCase().trim()))
                    : false,
        },
        {
            field: "isVisible",
            title: t(currentUserRight === "Global administrator" ? "Visible on Taxand website" : ""),
            sortable: false,
            editable: "never",
            filtering:false,
            render: (rowData: UserData) => (currentUserRight === "Global administrator" ? (rowData.isVisible === true ? "YES" : "NO") : ""),
        },

    ];

    // Handle Change Custom Filter

    const handleChangeCountry = useCallback((event, value) => {
        // Sort the value alphabetically by country name
        value.sort((a:any, b:any) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
        });
        setFilterCountry(value);
        setFilterTableActive(true)
    }, []);

    const handleChangeGrade = useCallback((event, value) => {
        setFilterGrade(value);
        setFilterTableActive(true)
    }, []);

    const handleChangeTaxSpecialism = useCallback((event, value) => {
        setFilterTaxSpecialism(value);
        setFilterTableActive(true)
    }, []);

    const handleChangeAreaOfIndustryExpertise = useCallback((event, value) => {
        setFilterAreaOfIndustryExpertise(value);
        setFilterTableActive(true)
    }, []);

    // Handle Change Open UserInfo Component
    const handleOpen = ( user: UserData) => {
        dataStore.updateUser(user);
        setOpenUserInfo(true);
    };



    const loadDataCountry = () => {
        countryRef.on('value', async (snap) => {
            let newListCountry: any[] = [];
            let result = snap.val();
            for (let country in result) {
                const detailedCountry = await API.getCountryInfo(country);
                newListCountry.push(detailedCountry);
            }
            dataStore.updateCountries(newListCountry)
        });
    }


    const loadDataGrade =() => {
        gradeRef.on('value', (snap) => {
            let result = snap.val();
            setListGrade(result);
        });
    }

    const loadDataTaxSpecialism = () => {
        taxSpecialismRef.on('value', (snap) => {
            let result = snap.val();
            setListTaxSpecialism(result);
        });
    }


    const loadDataAreaOfIndustryExpertise = async () => {
        areaOfIndustryExpertiseRef.on('value', async (snap) => {
            let areaOfIndustryExpertiseList: string[] = [];
            let areaOfIndustryExpertise = snap.val();
            for (let f in areaOfIndustryExpertise) {
                const detailedAreaOfExpertise = await API.getAreaOfExpertiseInfo(f);
                areaOfIndustryExpertiseList.push(detailedAreaOfExpertise);
            }
            setListAreaOfIndustryExpertise(areaOfIndustryExpertiseList);
        })
    }

    // load Current User
    const getCurrentUser = async () => {
        firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {
                const token = await user.getIdToken();
                setToken(token);
                let uid = user.uid;
                const userInfo = await API.getUserInfo(uid as string);
                setCurrentUserRight(userInfo.right as string);
                setCurrentUser(userInfo);
                setrightLoading(false);
            }
        });
    }

    //Delete User From Firebase And Wordpress
    const deleteUser = useCallback(async (userId: string) => {
        const userInfo = await API.getUserInfo(userId);
        try {
            // const updates = { deleted: true };
            // await firebase.database().ref('users/' + userId).update(updates);

            // Delete user from Firebase
            const responseDeleteUser = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/deleteUser`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    uid: userId,
                    callType: "",
                }),
            });

            if (!responseDeleteUser.ok) {
                throw new Error('Network response was not ok for delete user.');
            }

            if (userInfo.wordpress_id) {
                const responseDeleteUserWordpress = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/deleteUserWordpress`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        userIdWordpress: userInfo.wordpress_id,
                        token: localStorage.getItem("wordpressToken"),
                        callType: "",
                    }),
                });

                if (!responseDeleteUserWordpress.ok) {
                    throw new Error('Network response was not ok for delete user WordPress.');
                }
            }

            notification["success"]({
                message: "Success",
                description: "User deleted successfully",
            });

            API.getAllUserInfo().then(res => {
                dataStore.updateUsers(res);
                API.getDeletedUserInfo().then(delRes=>{
                    dataStore.updateDeletedUsers(delRes);
                    //setDataLoading(false);
                })
            })

            // Handle deletion of user avatar from Firebase Storage if exists
            if (userInfo.avatarUrl) {
                const imageRef = firebase.storage().ref("userAvatar/" + userId + ".jpg");
                imageRef.delete();
            }

        } catch (error:any) {
            notification["error"]({
                message: t("Failed to delete user"),
                description: `An error occurred while deleting the user: ${error.message}`,
            });
        }
    }, [t,token]);


    // Function responsible for the update of the user .
    const handleUpdate = useCallback(async (e: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement, MouseEvent>) =>{
            e.preventDefault();
            try {
                // Sample function to make the fetch call with types defined for parameters.
                const callFirebaseFunction = async (endpoint: string, method:string,data: Record<string, any>) => {
                    const response = await fetch(`${process.env.REACT_APP_CLOUD_FUNCTIONS}/${endpoint}`, {
                        method: method,
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                        body: JSON.stringify(data)
                    });
                    if (!response.ok) {
                        throw new Error('Error calling Firebase function: ' + endpoint);
                    }
                    return await response.json();
                }
                setWorking(true);
                //If File Not Changed And AvatarUrl Exist on Firebase So We Execute UploadUmageWordpress
                if (!fileChanged && dataRow.avatarUrl && isVisible) {
                    await uploadImageWordpress()
                }
                // If File Changed We Execute getMediaId() to Upload Image To Wordpress And Get Id
                if (fileChanged && isVisible) {
                    imageWordpressId = await getMediaId(); // eslint-disable-line react-hooks/exhaustive-deps
                }
                //Get Image Wordpress Id from Store depend on Witch last Two Condition Executed
                const image = dataStore.imageWordPressSelected;

                // If on Firebase IsVisble And On Web Form Not
                if (dataRow.isVisible === true && isVisible === false) {

                    // Update Native Data Wordpress For The User Exist Already
                    await callFirebaseFunction('updateUserWordpressNative','PUT', {
                        userIdWordpress: dataRow.wordpress_id,
                        token: localStorage.getItem("wordpressToken"),
                        first_name: dataRow.firstName,
                        last_name: dataRow.lastName,
                        name: dataRow.firstName + " " + dataRow.lastName,
                        url: !!firm.webSite ? firm.webSite : "",
                        callType: ""
                    });

                    // Update Acf Data Wordpress For The User Exist Already
                    await callFirebaseFunction('updateUserWordpressAcf','PUT', {
                        userIdWordpress: !!dataRow.wordpress_id ? dataRow.wordpress_id : wordpress_id,
                        token: localStorage.getItem("wordpressToken"),
                        image: !!imageWordpressId && !pictureDeleted ? imageWordpressId : !!image && !pictureDeleted ? image : "",
                        telephone: dataRow.phone,
                        mobile: dataRow.mobile,
                        biography: dataRow.profesionnalBiography,
                        connect_url: dataRow.linkedInProfileLink,
                        assistants_name: dataRow.assistantName,
                        assistants_email: dataRow.assistantEmail,
                        position: grade,
                        user_location: countryId,
                        show: isVisible,
                        area_of_expertise: taxSpecialism?.length > 0 ? filterTaxSpecialisme(taxSpecialism) : "",
                        company_logo: firm.logo_id,
                        callType: ""

                    });
                }

                // If In The WebForm IsVisible
                if (isVisible === true) {
                    // Build Acf Data
                    const userWordpressUpdateACF = {
                        fields: {
                            //display_email: dataRow.email,
                            image: imageWordpressId && !pictureDeleted ? imageWordpressId : image && !pictureDeleted ? image : 99999999,
                            telephone: dataRow.phone,
                            mobile: dataRow.mobile,
                            biography: !!dataRow.profesionnalBiography ? textFormat(dataRow.profesionnalBiography) : "",
                            connect_url: dataRow.linkedInProfileLink,
                            assistants_name: dataRow.assistantName,
                            assistants_email: dataRow.assistantEmail,
                            position: grade,
                            user_location: countryId,
                            show: isVisible,
                            area_of_expertise: taxSpecialism?.length > 0 ? filterTaxSpecialisme(taxSpecialism) : "",
                            company_logo: firm.logo_id
                        }
                    }
                    //Build Native Data
                    let randomCharacter = characters.charAt(Math.floor(Math.random() * characters.length))
                    const userWordpressUpdateNative = {
                        name: dataRow.firstName.replace(/[^a-zA-Z0-9]/g, randomCharacter) + " " + dataRow.lastName.replace(/[^a-zA-Z0-9]/g, randomCharacter),
                        first_name: dataRow.firstName,
                        last_name: dataRow.lastName,
                        url: !!firm.webSite ? firm.webSite : "",
                    }

                    // If User Has Already Wordpress Id
                    if (dataRow.wordpress_id) {
                        // Update Native Data Wordpress For The User Exist Already
                        await callFirebaseFunction('updateUserWordpressNative','PUT', {
                            userIdWordpress: dataRow.wordpress_id,
                            token: localStorage.getItem("wordpressToken"),
                            first_name: userWordpressUpdateNative.first_name,
                            last_name: userWordpressUpdateNative.last_name,
                            name: userWordpressUpdateNative.name,
                            url: userWordpressUpdateNative.url,
                            callType: ""

                        });
                    }
                    // Id User Has Not Wordpress Id
                    if (!dataRow.wordpress_id) {
                        const res = await callFirebaseFunction('createUserWordPressNativeFunction', 'POST', {
                            token: localStorage.getItem("wordpressToken"),
                            name: userWordpressUpdateNative.name,
                            username: userWordpressUpdateNative.name,
                            first_name: userWordpressUpdateNative.first_name,
                            last_name: userWordpressUpdateNative.last_name,
                            email: dataRow.email,
                            password: "i5Z%sTF6tdu&#*h#ydq$J%PM",
                            url: userWordpressUpdateNative.url,
                            description: "",
                            callType: ""
                        });
                        if (res.data && res.data.userId) {
                            // eslint-disable-next-line
                            wordpress_id = res.data.userId;
                        } else {
                            notification["error"]({
                                message: "User created but no ID returned",
                                description: "User created but no ID returned",
                            });
                        }
                    }

                    // For The Two last Condition If He Has Wordpress Or Not We Update ACF Data
                    await callFirebaseFunction('updateUserWordpressAcf', 'PUT',{
                        userIdWordpress: !!dataRow.wordpress_id ? dataRow.wordpress_id : wordpress_id,
                        token: localStorage.getItem("wordpressToken"),
                        image: userWordpressUpdateACF.fields.image,
                        telephone: userWordpressUpdateACF.fields.telephone,
                        mobile: userWordpressUpdateACF.fields.mobile,
                        biography: userWordpressUpdateACF.fields.biography,
                        connect_url: userWordpressUpdateACF.fields.connect_url,
                        assistants_name: userWordpressUpdateACF.fields.assistants_name,
                        assistants_email: userWordpressUpdateACF.fields.assistants_email,
                        position: userWordpressUpdateACF.fields.position,
                        user_location: userWordpressUpdateACF.fields.user_location,
                        show: userWordpressUpdateACF.fields.show,
                        area_of_expertise: userWordpressUpdateACF.fields.area_of_expertise,
                        company_logo: userWordpressUpdateACF.fields.company_logo,
                        callType: ""

                    });
                }

                // Build Data For Firebase database
                const userUpdate = {
                    id: dataRow.id !== undefined ? dataRow.id : "",
                    email: dataRow.email !== undefined ? dataRow.email : "",
                    firstName: dataRow.firstName !== undefined ? dataRow.firstName : "",
                    lastName: dataRow.lastName !== undefined ? dataRow.lastName : "",
                    phone: dataRow.phone !== undefined ? dataRow.phone : "",
                    mobile: dataRow.mobile !== undefined ? dataRow.mobile : "",
                    deleted: dataRow.deleted !== undefined ? dataRow.deleted : "",
                    personnalBiography: dataRow.personnalBiography !== undefined ? dataRow.personnalBiography : "",
                    profesionnalBiography: dataRow.profesionnalBiography !== undefined ? dataRow.profesionnalBiography : "",
                    linkedInProfileLink: dataRow.linkedInProfileLink !== undefined ? dataRow.linkedInProfileLink : "",
                    twitterProfileLink: dataRow.twitterProfileLink !== undefined ? dataRow.twitterProfileLink : "",
                    assistantId:!!assistant.id?assistant.id:"",
                    assistantName: dataRow.assistantName !== undefined ? dataRow.assistantName : "",
                    assistantEmail: dataRow.assistantEmail !== undefined ? dataRow.assistantEmail : "",
                    assistantTelephone: dataRow.assistantTelephone !== undefined ? dataRow.assistantTelephone : "",
                    assistantMobile: dataRow.assistantMobile !== undefined ? dataRow.assistantMobile : "",
                    grade: grade === undefined ? "" : grade,
                    country: country === undefined ? "" : country,
                    isVisible: isVisible === undefined ? "" : isVisible,
                    right: right === undefined ? "" : right,
                    firm: firm === undefined ? "" : firm,
                    taxSpecialisme: taxSpecialism?.length > 0 ? taxSpecialism : "",
                    areaOfIndustryExpertise: areaOfIndustryExpertise?.length > 0 ? areaOfIndustryExpertise : "",
                    wordpress_id:!!dataRow.wordpress_id ? dataRow.wordpress_id : !!wordpress_id ? wordpress_id:"",
                    approvalStatus: ApprovalStatus.Default,
                    lastUpdated: new Date().toISOString().replace('T', ' ').substr(0, 10)
                };
                if (userUpdate.right === "Assistant") {
                    updateUserAssitantInfo(userUpdate.id);
                }
                // Update User Firebase

                // If File Changed Upload Image Using Id Of User
                if (fileChanged) {
                    await uploadImage(userUpdate.id);
                    setFileChanged(false);
                }

                if (pictureDeleted) {
                    await deleteImageFirebase(userUpdate.id);
                }

                const res = await API.updateUser(userUpdate, dataRow.id);

                dataStore.updateImageWordPressSelected(0);
                if(userUpdate.id === currentUser.id) {
                    const completeUser = {
                        ...userUpdate,
                        avatarUrl: pictureDeleted ? "" : dataRow.avatarUrl,
                    };
                    dataStore.updateUserInfo(completeUser);
                }

                if (res) {
                    loadData();
                    // On Success Stop Submit And Close Model and Return Message Success
                    notification["success"]({
                        message: "Success",
                        description: `User updated successfully`,
                        style: {fontFamily: 'ArialBD'},
                    });
                    setData({
                        email: "",
                        password: "",
                        againPassword: "",
                        firstName: "",
                        lastName: "",
                        generatedPass: "",
                        phone: "",
                        mobile: "",
                        personnalBiography: "",
                        profesionnalBiography: "",
                        deleted: false,
                        linkedInProfileLink: "",
                        twitterProfileLink: "",
                        assistantName: "",
                        assistantEmail: "",
                        assistantTelephone: "",
                        assistantMobile: "",
                        showLandingModal:false,
                        approvalStatus: ApprovalStatus.Default
                    });
                    setTaxSpecialism([]);
                    setGrade("");
                    setFirm("");
                    setCountry("");
                    setAreaOfIndustryExpertise([]);
                    setRight("");
                    setIsVisible(false)
                    setEmailVerified(true)
                    setEmailValid(true)
                    setAssistant('');
                    setPictureDeleted(false)
                    setWorking(false);
                    //setDataLoading(false);
                    // close modal

                    setOpenUpdateUser(false);
                }

            } catch (error) {
                // On Error Stop Submit And Close Model and Return Message Error
                messageStore.snackbar({
                    message: t("Failed to update user"),
                    type: "error",
                });
                setWorking(false)
            }

        },
        [right, pictureDeleted, fileChanged, file1,working, assistant, isVisible, countryId, firm,
            areaOfIndustryExpertise, taxSpecialism, grade, country, dataRow, t, DeletedusernameVerified]// eslint-disable-line react-hooks/exhaustive-deps
    )

    //export to excel
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const exportXlsx = useCallback(() => {
        const dataGlobal: any = [];
        const column =['First Name',"Last Name","Email","Country","Firm","Phone","Mobile","Grade","Personal biography","Professional biography",
            "Linkedin Profile Link","Twitter Profile Link","Assistant Name","Assistant Email","Assistant Telephone","Assistant Mobile","Visible on",
            "Area of Industry Expertise","Tax Specialism","Last Updated"]
        let users=rows.slice().sort((a:any, b:any) => a.lastName?.replace(/\s/g, "")+""+a.firstName?.replace(/\s/g, "") > b.lastName?.replace(/\s/g, "")+""+b.firstName?.replace(/\s/g, "") ? 1 : -1)
        // for (let userId in users) {
        //     if (!users[userId].deleted) {
        //         let user: any = [];
        //         user.push(users[userId].firstName);
        //         user.push(users[userId].lastName);
        //         user.push(users[userId].email);
        //         user.push(users[userId].country);
        //         user.push(users[userId].firm?.firm);
        //         user.push(users[userId].phone);
        //         user.push(users[userId].mobile);
        //         user.push(users[userId].grade);
        //         user.push(users[userId].personnalBiography);
        //         user.push(users[userId].profesionnalBiography);
        //         user.push(users[userId].linkedInProfileLink);
        //         user.push(users[userId].twitterProfileLink);
        //         user.push(users[userId].assistantName);
        //         user.push(users[userId].assistantEmail);
        //         user.push(users[userId].assistantTelephone);
        //         user.push(users[userId].assistantMobile);
        //         user.push(users[userId].isVisible);
        //         if (users[userId].areaOfIndustryExpertise) {
        //             user.push(users[userId].areaOfIndustryExpertise?.join());
        //         } else {
        //             user.push(users[userId].areaOfIndustryExpertise)
        //         }
        //         if (users[userId].taxSpecialisme) {
        //             user.push(users[userId].taxSpecialisme?.join());
        //         } else {
        //             user.push(users[userId].taxSpecialisme)
        //         }
        //         user.push(users[userId].lastUpdated);
        //         dataGlobal.push(user)
        //         user = []
        //     }
        // }
        for (let userId in users) {
            if (!users[userId].deleted) {
                let user = [
                    users[userId].firstName || "",
                    users[userId].lastName || "",
                    users[userId].email || "",
                    users[userId].country || "",
                    users[userId].firm?.firm || "",
                    users[userId].phone || "",
                    users[userId].mobile || "",
                    users[userId].grade || "",
                    users[userId].personnalBiography || "",
                    users[userId].profesionnalBiography || "",
                    users[userId].linkedInProfileLink || "",
                    users[userId].twitterProfileLink || "",
                    users[userId].assistantName || "",
                    users[userId].assistantEmail || "",
                    users[userId].assistantTelephone || "",
                    users[userId].assistantMobile || "",
                    users[userId].isVisible || "",
                    users[userId].areaOfIndustryExpertise ? users[userId].areaOfIndustryExpertise.join() : "",
                    users[userId].taxSpecialisme ? users[userId].taxSpecialisme.join() : "",
                    users[userId].lastUpdated || ""
                ];

                // Check if at least one value in the user array is non-empty
                const hasData = user.some(value => value !== "");

                if (hasData) {
                    dataGlobal.push(user);
                }
            }
        }
        dataGlobal.sort((a: any, b: any) => a[1] + "" + a[0] > b[1] + "" + b[0] ? 1 : -1)
        dataGlobal.splice(0, 0, column);
        const timestamp = new Date().toISOString().replace('T', ' ').substr(0, 10);
        const ws = XLSX.utils.aoa_to_sheet(dataGlobal);
        const Header=['A1','B1','C1','D1','E1','F1','G1','H1','I1','J1','K1','L1','M1','N1','O1','P1','Q1','R1','S1','T1'];
        for(let h in Header){
            ws[Header[h]].s = {
                font: {
                    sz: 12,
                    color: { rgb: "#FF000000" },
                    bold: true,
                    italic: false,
                    underline: false
                }}
        }
        const wb = {Sheets: {'data': ws}, SheetNames: ['data']};
        const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array',cellStyles: true});
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, "taxand_repository_" + timestamp + fileExtension);
    },[rows])

    // First Load Component
    useEffect(() => {
        loadData().then();
        getCurrentUser().then();
        loadDataCountry();
        loadDataGrade();
        loadDataTaxSpecialism();
        loadDataAreaOfIndustryExpertise().then();
        // Cleanup function to remove listeners when component unmounts
        return () => {
            countryRef.off('value');  // Remove country listener
            gradeRef.off('value');    // Remove grade listener
            taxSpecialismRef.off('value');  // Remove tax specialism listener
            areaOfIndustryExpertiseRef.off('value');  // Remove industry expertise listener
        };
    }, [loadData,file1]);

    return (
        <div className={styles.root}>
            <Skeleton  loading={rightLoading} active  >
                {currentUserRight === "Global administrator" ? (
                    <>
                        <div style={{display: "flex", gap: "10px", flexWrap: "wrap"}}>
                            <Button type="primary"
                                    size='large'
                                    style={{fontFamily: 'Arial', fontSize: "14px"}}
                                    icon={<PlusOutlined/>}
                                    onClick={() => setOpenNewUser(true)}>
                                Add new professional
                            </Button>
                            <Button
                                type="primary"
                                size='large'
                                style={{fontFamily: 'Arial', fontSize: "14px"}}
                                icon={<ExportOutlined/>}
                                onClick={(e) => exportXlsx()}
                            > Export
                            </Button>
                            <Button type="primary"
                                    size='large'
                                    style={{fontFamily: 'Arial', fontSize: "14px"}}
                                    icon={<PlusOutlined/>}
                                    onClick={() => setOpenImport(true)}>
                                User Management Hub
                            </Button>
                        </div>
                    </>
                ) : (
                    <></>
                )}
                <div className="FiltersContainer">
                    <Autocomplete
                        multiple
                        id="demo-simple-selectCountry"
                        options={listCountries.slice().sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))}
                        disableCloseOnSelect
                        value={filterCountry}
                        size='medium'
                        style={{background: "#FFFF",borderRadius: "10px",width : "90%" , marginRight : "10px",marginBottom : "10px"}}
                        getOptionLabel={(option) => option}
                        getOptionSelected={(option, value) => option === value}
                        onChange={handleChangeCountry}
                        renderOption={(option, {selected}) => (
                            <React.Fragment>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{marginRight:8,color:"#222850"}}
                                    checked={selected}
                                />
                                {option}
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} label="Filter by Country"
                                       placeholder="Country" variant="outlined" className = "textfield"/>
                        )}/>
                    <Autocomplete
                        multiple
                        id="checkboxes-tags-TaxSpecialism"
                        options={listTaxSpecialism.slice().sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))}
                        disableCloseOnSelect
                        value={filterTaxSpecialism}
                        style={{background: "#FFFF",borderRadius: "10px",width : "90%" , marginRight : "10px",marginBottom : "10px"}}
                        getOptionLabel={(option) => option}
                        getOptionSelected={(option, value) => option === value}
                        onChange={handleChangeTaxSpecialism}
                        renderOption={(option, {selected}) => (
                            <React.Fragment>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{marginRight: 8,color:"#222850"}}
                                    checked={selected}
                                />
                                {option}
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} label=" Filter by Tax Specialism"
                                       variant="outlined"
                                       placeholder="Tax Specialism"/>
                        )}/>
                    <Autocomplete
                        multiple
                        id="checkboxes-tags-AreaOfIndustryExpertise"
                        options={listAreaOfIndustryExpertise.slice().sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))}
                        disableCloseOnSelect
                        value={filterAreaOfIndustryExpertise}
                        style={{background: "#FFFF",borderRadius: "10px",width : "90%" , marginRight : "10px" , marginBottom : "10px"}}
                        getOptionLabel={(option) => option}
                        onChange={handleChangeAreaOfIndustryExpertise}
                        renderOption={(option, {selected}) => (
                            <React.Fragment>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{marginRight: 8,color:"#222850"}}
                                    checked={selected}
                                />
                                {option}
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} label="Filter by Industry expertise"
                                       variant="outlined"
                                       placeholder="Industry expertise" style={{color:"#FF696999"}} />
                        )}/>
                    <Autocomplete
                        id="demo-simple-selectGrade"
                        options={listGrade.slice().sort((a:string, b:string) => a.toLowerCase().localeCompare(b.toLowerCase()))}
                        disableCloseOnSelect
                        value={filterGrade}
                        style={{background: "#FFFF",borderRadius: "10px",width : "90%" , marginRight : "10px",marginBottom : "10px"}}
                        getOptionLabel={(option) => option}
                        getOptionSelected={(option, value) => option === value}
                        onChange={handleChangeGrade}
                        renderOption={(option, {selected}) => (
                            <React.Fragment>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{marginRight:8,color:"#222850"}}
                                    checked={selected}
                                    color="secondary"
                                />
                                {option}
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} className={classes.textfield} label="Filter by Grade"
                                       placeholder="Grade"
                                       variant="outlined"/>
                        )}/>
                </div>

                <div className="TableContainer UsersTable">
                    <MaterialTable
                        icons={{ Filter:React.forwardRef((props, ref) => <Search />) }as any}
                        title={undefined}
                        columns={headCells}
                        onRowClick={(event:any,rowData)=>{
                            setDataRow(rowData);
                            handleOpen( rowData as any);
                        }}
                        data={rows.slice().sort((a:any, b:any) => a.lastName?.replace(/\s/g, "")+""+a.firstName?.replace(/\s/g, "") > b.lastName?.replace(/\s/g, "")+""+b.firstName?.replace(/\s/g, "") ? 1 : -1)}
                        onFilterChange={(filters) => {
                            setFilterTable(filters)
                            setFilterTableActive(true)
                        }}
                        options={{
                            padding: true ? "dense" : "default",
                            actionsColumnIndex: -1,
                            pageSize: 10,
                            paging: true,
                            filtering: true,
                            search: false,
                            showTitle: false
                        }}
                        actions={
                            currentUserRight === "Global administrator" ?
                                [
                                    rowData => (
                                        {
                                            icon: "edit",
                                            tooltip: "Edit",
                                            onClick: () => {
                                                setDataRow(rowData);
                                                setIsVisible(rowData.isVisible)
                                                setTaxSpecialism(rowData.taxSpecialisme)
                                                setGrade(rowData.grade);
                                                setCountry(rowData.country);
                                                setFirm(rowData.firm);
                                                setRight(rowData.right);
                                                setAreaOfIndustryExpertise(rowData.areaOfIndustryExpertise);
                                                setOpenUpdateUser(true);
                                                setFileChanged(false)
                                            }
                                        }
                                    ),
                                    rowData => (
                                        {
                                            icon: "info",
                                            tooltip: " User Info",
                                            onClick: (event) => {
                                                setDataRow(rowData);
                                                handleOpen( rowData as any);
                                            },
                                        }
                                    )] : [
                                    rowData => (
                                        {
                                            icon: "info",
                                            tooltip: " User Info",
                                            onClick: (event) => {
                                                setDataRow(rowData);
                                                handleOpen( rowData as any);
                                            },
                                        }
                                    ),
                                ]
                        }
                        editable={currentUserRight === "Global administrator" ? {
                            onRowDelete: (oldData) =>
                                new Promise((resolve,reject) => {
                                    deleteUser(oldData.id as any).then(()=>{
                                        API.getAllUserInfo().then(res => {
                                            dataStore.updateUsers(res);
                                            setRows(res);
                                            API.getDeletedUserInfo().then(delRes=>{
                                                dataStore.updateDeletedUsers(delRes);
                                                //setDataLoading(false);
                                            })
                                        })
                                        resolve('done');
                                    }).catch(err => reject(err))
                                }),
                        } : {} as any}
                        components={{
                        Pagination: (props) => (
                            <TablePagination
                                {...props}
                                onRowsPerPageChange={(event) => {
                                    props.onChangePage(0); // Reset to the first page
                                    props.onRowsPerPageChange(event);
                                }}
                            />
                        ),
                    }}
                    />
                </div>
                <Modal
                    title="Add professional"
                    visible={openNewUser}
                    onCancel={() => {
                        setOpenNewUser(false);
                        setAssistants([]);
                        setAssistants([]);

                        setData({
                            email: "",
                            password: "",
                            againPassword: "",
                            firstName: "",
                            lastName: "",
                            generatedPass: "",
                            phone: "",
                            mobile: "",
                            personnalBiography: "",
                            profesionnalBiography: "",
                            deleted: false,
                            linkedInProfileLink: "",
                            twitterProfileLink: "",
                            assistantName: "",
                            assistantEmail: "",
                            assistantTelephone: "",
                            assistantMobile: "",
                            showLandingModal:false,
                            approvalStatus: ApprovalStatus.Default
                        });
                        setTaxSpecialism([]);
                        setGrade("");
                        setFirm("");
                        setCountry("");
                        setAreaOfIndustryExpertise([]);
                        setRight("");
                        setIsVisible(false)
                        setEmailVerified(true)
                        setEmailValid(true)
                        setAssistant('');
                    }}
                    okText='Create'
                    width={'80%'}
                    style={{top:' 5%'}}
                    destroyOnClose
                    footer={[
                        <Button key="back" onClick={() => {
                            setOpenNewUser(false);
                            setAssistants([]);
                            setIsVisible(false)
                        }}>
                            Return
                        </Button>,
                        <Button key="submit" type="primary" disabled={!canSubmit || shortPassword || !emailVerified || !emailValid || !usernameVerified || right === "" || country === "" || firm === ""} loading={working}
                                onClick={(e) => {
                                    handleSubmit(e);
                                    setTimeout(()=>loadData(),7000)
                                }}
                        >
                            Create
                        </Button>,
                    ]}
                    bodyStyle={{ height: "70vh", overflowY: "scroll" }}
                >
                    <>
                        <AddUserTaxand
                            data={data}
                            setUsernameVerified={setUsernameVerified}
                            setEmailVerified={setEmailVerified}
                            setEmailValid={setEmailValid}
                            emailValid={emailValid}
                            setDeletedUsernameVerified={setDeletedUsernameVerified}
                            setData={setData}
                            usernameVerified={usernameVerified}
                            emailVerified={emailVerified}
                            canSubmit={canSubmit}
                            isVisible={isVisible}
                            setIsVisible={setIsVisible}
                            grade={grade}
                            setGrade={setGrade}
                            country={country}
                            setCountry={setCountry}
                            areaOfIndustryExpertise={areaOfIndustryExpertise}
                            setAreaOfIndustryExpertise={setAreaOfIndustryExpertise}
                            taxSpecialism={taxSpecialism}
                            setTaxSpecialism={setTaxSpecialism}
                            fileChanged={fileChanged}
                            setFileChanged={setFileChanged}
                            right={right}
                            setRight={setRight}
                            firm={firm}
                            setFirm={setFirm}
                            assistant={assistant}
                            setAssistant={setAssistant}
                            getCountryId={getCountryId}
                            setfile1={setfile1}
                            assistants={assistants}
                            setAssistants={setAssistants}
                            action="Add"
                            setAction={setAction}
                            file1={file1}
                            openNewUser={openNewUser}
                            pictureDeleted={pictureDeleted}
                            setPictureDeleted={setPictureDeleted}
                        />
                    </>
                </Modal>
                <Modal
                    title="Update user"
                    visible={openUpdateUser}
                    onCancel={() => {
                        setOpenUpdateUser(false)
                        setAssistants([]);
                        setIsVisible(false)
                    }}
                    width={'80%'}
                    style={{top:' 5%'}}
                    bodyStyle={{ height: "70vh", overflowY: "scroll" }}
                    destroyOnClose
                    footer={[
                        <Button key="back" onClick={() => {
                            setOpenUpdateUser(false)
                            setAssistants([]);
                        }}>
                            Return
                        </Button>,
                        <Button key="submit" type="primary" disabled={!usernameVerified} loading={working}  onClick={(e)=>handleUpdate(e)}>
                            Update
                        </Button>,
                    ]}

                >
                    <>
                        <AddUserTaxand
                            data={dataRow}
                            setUsernameVerified={setUsernameVerified}
                            setEmailVerified={setEmailVerified}
                            setDeletedUsernameVerified={setDeletedUsernameVerified}
                            setData={setDataRow}
                            usernameVerified={usernameVerified}
                            emailVerified={emailVerified}
                            emailValid={emailValid}
                            setEmailValid={setEmailValid}
                            canSubmit={canSubmit}
                            isVisible={isVisible}
                            setIsVisible={setIsVisible}
                            grade={grade}
                            setGrade={setGrade}
                            country={country}
                            setCountry={setCountry}
                            areaOfIndustryExpertise={areaOfIndustryExpertise}
                            setAreaOfIndustryExpertise={setAreaOfIndustryExpertise}
                            taxSpecialism={taxSpecialism}
                            setTaxSpecialism={setTaxSpecialism}
                            fileChanged={fileChanged}
                            setFileChanged={setFileChanged}
                            right={right}
                            setRight={setRight}
                            firm={firm}
                            setFirm={setFirm}
                            assistant={assistant}
                            setAssistant={setAssistant}
                            getCountryId={getCountryId}
                            setfile1={setfile1}
                            assistants={assistants}
                            setAssistants={setAssistants}
                            action="Edit"
                            setAction={setAction}
                            pictureDeleted={pictureDeleted}
                            setPictureDeleted={setPictureDeleted}
                        />
                    </>
                </Modal>
                <Modal
                    title={`${dataRow.firstName} ${dataRow.lastName}`}
                    visible={openUserInfo}
                    onCancel={() => {
                        setOpenUserInfo(false);
                    }}
                    width={'80%'}
                    style={{top:' 5%'}}
                    bodyStyle={{ height: "70vh", overflowY: "scroll" }}
                    destroyOnClose
                    footer={null}
                >
                    <>
                        <UserInfo
                            data={dataRow}
                            assistant={assistant}
                            assistants={assistants}
                        />
                    </>
                </Modal>
                <Modal
                    title="User Management Hub"
                    visible={openImport}
                    onCancel={() => setOpenImport(false)}
                    width={'70%'}
                    style={{ top: '5%' }}
                    bodyStyle={{
                        overflowY: "hidden",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                        justifyContent: "space-around",
                        paddingTop: "5px"
                    }}
                    destroyOnClose
                    footer={null}
                >
                    <ExportUser/>
                </Modal>

            </Skeleton>
        </div>

    );
};

export default inject("dataStore", "messageStore")(observer(Users));
