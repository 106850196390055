import React, { useState } from 'react';
import { IconLink } from "@tabler/icons";
import { ActionIcon, Group, Tooltip, Input } from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons';
import { createLink } from 'helpers/DocumentHandlers';

type Props = {
    checkLinkExistFunction: () => void;
    setCheckLinkExistParam: (value: React.SetStateAction<boolean>) => void;
    setLinkName: (value: any) => void;
    linkName: string;
    checkLinkExistParam: boolean;
    setIsNewLink: (value: React.SetStateAction<boolean>) => void;
    loadingSave: boolean;
    setLoadingSave: React.Dispatch<React.SetStateAction<boolean>>;
    currentPath: string;
    setCurrentPath: React.Dispatch<React.SetStateAction<string>>;
    setParent: React.Dispatch<React.SetStateAction<string>>;
    params: any;
};

export default function NewLinkItem({
                                        checkLinkExistFunction,
                                        setCheckLinkExistParam,
                                        setLinkName,
                                        linkName,
                                        checkLinkExistParam,
                                        setIsNewLink,
                                        setLoadingSave,
                                        setCurrentPath,
                                        setParent,
                                        params,
                                        currentPath,
                                        loadingSave,
                                    }: Props) {
    // State for the link URL
    const [linkURL, setLinkURL] = useState('');

    return (
        <div className="item-link link create-active">
            <div className="header-container">
                <IconLink className="link-icon" />
            </div>
            <div className="input-action-container" style={{ marginTop: '10px', gap: '10px' }}>
                {/* Input for Link Name */}
                <div style={{ width: '50%' }}>
                    <Input
                        onBlur={checkLinkExistFunction}
                        variant="filled"
                        required
                        name="linkName"
                        size="xs"
                        radius="md"
                        placeholder="Link name"
                        className="input-action"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setCheckLinkExistParam(false);
                            setLinkName(e.target.value);
                        }}
                        value={linkName}
                        invalid={checkLinkExistParam}
                    />
                    {checkLinkExistParam && (
                        <label className="ErrorLabel">Link name already exists</label>
                    )}
                </div>

                {/* Input for Link URL */}
                <div style={{ width: '95%' }}>
                    <Input
                        variant="filled"
                        required
                        name="linkURL"
                        size="xs"
                        radius="md"
                        placeholder="Link URL"
                        className="input-action"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setLinkURL(e.target.value);
                        }}
                        value={linkURL}
                    />
                </div>

                {/* Action Buttons */}
                <Group spacing={0} position="right" className="group-action-container">
                    <Tooltip label="Cancel" color="dark" withArrow>
                        <ActionIcon color="red" onClick={() => setIsNewLink(false)}>
                            <IconX size={10} stroke={1.5} />
                        </ActionIcon>
                    </Tooltip>
                    <Tooltip label="Save" color="dark" withArrow>
                        <ActionIcon
                            loading={loadingSave}
                            color="green"
                            onClick={(
                                e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement, MouseEvent>
                            ) =>
                                createLink(
                                    e,
                                    setLoadingSave,
                                    checkLinkExistParam,
                                    currentPath,
                                    setCurrentPath,
                                    setParent,
                                    params,
                                    linkName,
                                    setLinkName,
                                    setIsNewLink,
                                    linkURL
                                )
                            }
                        >
                            <IconCheck size={10} stroke={1.5} />
                        </ActionIcon>
                    </Tooltip>
                </Group>
            </div>
        </div>
    );
}
