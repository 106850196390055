import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Post } from "../../../models/post";
import { notification, Result } from "antd";
import { Skeleton } from "@material-ui/lab";
import { TextInput } from "@mantine/core";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { IconSearch } from "@tabler/icons";
import { ReactComponent as SearchErrorIcon } from "assets/search-error.svg";
import moment from "moment";
import './News.scss';

const News = () => {
    const { t } = useTranslation();

    // State variables
    const [posts, setPosts] = useState<Post[]>([]);
    const [loading, setLoading] = useState(false);
    const [searchPosts, setSearchPosts] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const [page, setPage] = useState(1); // Track the current page

    // Link of WordPress posts API
    const baseUrl = 'https://www.taxand.com';
    const postsEndpoint = '/wp-json/wp/v2/posts?categories=16';
    const perPage = 12;

    // Fetch and sort posts with pinned ones first
    const getAllPosts = useCallback(async (pageNumber = 1, searchQuery = '') => {
        setLoading(true);
        try {
            // Adjust the API URL to include pagination and search parameters
            let apiUrl = `${baseUrl}${postsEndpoint}&page=${pageNumber}&per_page=${perPage}`;
            if (searchQuery) {
                apiUrl += `&search=${encodeURIComponent(searchQuery)}`;
            }

            const response = await fetch(apiUrl);
            const resJson = await response.json();

            if (Array.isArray(resJson)) {
                // Sort posts with pinned ones first
                const sortedAllPosts = resJson.sort((a, b) => {
                    const aPin = a.acf?.pin_news === true;
                    const bPin = b.acf?.pin_news === true;

                    if (bPin && !aPin) return 1;  // `b` is pinned, `a` is not - `b` comes first
                    if (!bPin && aPin) return -1; // `a` is pinned, `b` is not - `a` comes first
                    return 0; // Both have the same pin status
                });

                // Update posts based on search or initial fetch
                if (pageNumber === 1) {
                    setPosts(sortedAllPosts);
                } else {
                    setPosts(prevPosts => [...prevPosts, ...sortedAllPosts]);
                }
            } else {
                console.error('resJson is not an array:', resJson);
            }

        } catch (error) {
            notification['error']({
                message: 'Error',
                description: t('There was an error displaying the news, please refresh the page'),
            });
        } finally {
            setLoading(false);
        }
    }, [t, baseUrl, postsEndpoint, perPage]);

    // Handle search input change
    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchValue = e.target.value.toLowerCase();
        setSearchPosts(searchValue);
        setIsSearching(true);

        if (searchValue === "") {
            // Reset to initial posts with pagination when search is cleared
            setIsSearching(false);
            getAllPosts(1).then();
        } else {
            // Fetch posts based on the search query using the API
            getAllPosts(1, searchValue).then();
        }
    };

    const handlePageChange = async (newPage: number) => {
        setPage(newPage);
        // Fetch new posts based on the new page number
        await getAllPosts(newPage);
    };

    // Fetch posts when the component mounts
    useEffect(() => {
        getAllPosts().then();
    }, [getAllPosts]);

    return (
        <>
            <h3 style={{ fontWeight: "bold" }}>Latest news</h3>
            <TextInput
                placeholder="Search..."
                className="search-input"
                radius="md"
                size="md"
                rightSection={<IconSearch style={{ width: '18px', height: '18px', color: '#ced4da' }} />}
                value={searchPosts}
                onChange={handleSearch}
            />
            <section className="news-section">
                {isSearching && posts.length === 0 && (
                    <Result
                        icon={<SearchErrorIcon />}
                        title="No data found"
                        subTitle="Try adjusting your search to find what you're looking for."
                    />
                )}
                {posts.map((post, index) => (
                    <a key={`${post.id}-${index}`} href={post.link} target="_blank" rel="noopener noreferrer">
                        <article key={post.id} className="news-card">
                            <div className="news-img">
                                {loading ? (
                                    <Skeleton variant="rect" animation="wave" height={190} width="100%" />
                                ) : (
                                    <img
                                        loading="lazy"
                                        alt={post.acf.image.title}
                                        src={post.acf.image.url + '.webp'}
                                    />
                                )}
                            </div>
                            <div className="news-content">
                                {loading ? (
                                    <>
                                        <Skeleton animation="wave" height={10} width="100%" />
                                        <Skeleton animation="wave" height={10} width="100%" />
                                        <Skeleton animation="wave" height={30} width="100%" />
                                    </>
                                ) : (
                                    <>
                                        <h3 dangerouslySetInnerHTML={{ __html: post.title.rendered }}></h3>
                                        <time>
                                            <CalendarTodayIcon className="calendar-icon" />
                                            {moment(post.date).format('LL')}
                                        </time>
                                        <p>{post.yoast_head_json.description?.slice(0, 60)}...</p>
                                    </>
                                )}
                            </div>
                        </article>
                    </a>
                ))}
            </section>
            <div className="news-pagination" hidden={isSearching}>
                <button onClick={() => handlePageChange(page + 1)} className="btn" disabled={loading} >
                    <span className="btn-text-one">Load More</span>
                    <span className="btn-text-two">Load More</span>
                </button>
            </div>
        </>
    );
};

export default News;